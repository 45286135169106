import { nextTick } from 'vue'

const CHUNK_SIZE = 2 // 商品列表每次渲染的数量，强制为2，因为商品列表的样式是两列
const MAX_LIMIT_SIZE = 6 // 前6个商品使用批量渲染，超过6个商品则使用普通渲染

const yieldStrategy = () => {
  if (typeof window.scheduler?.yield === 'function') {
    return window.scheduler.yield()
  }
  return new Promise(resolve => requestAnimationFrame(resolve))
}

const isDebug = () => typeof window !== 'undefined' && gbCommonInfo.isDebug
function warn(msg) {
  if (!isDebug()) return
  // eslint-disable-next-line no-console
  console.log(`%c [Batch Renderer warn] ${msg}`, 'color: #ffffff; padding: 1px; font-size: 10px; border: 1px  solid #f0ad4e; background: #111111;')
}
function _log(msg) {
  if (!isDebug()) return
  // eslint-disable-next-line no-console
  console.log(`%c [Batch Renderer log] ${msg}`, 'color: #ffffff; padding: 1px; font-size: 10px; border: 1px  solid #5d8e4e; background: #111111;')
}
function isBatchOperation() {
  if (typeof window === 'undefined') return
  return gbCommonInfo.PRODUCTLIST_BATHCH_RENDER !== 'OFF'
}

/**
 * 每次push一个商品，总的商品数量不超过totalSize
*/
function appendItemGoods(goods, Results, splitCount, init) {
  const updatableGoods = init ? [] : [...(Results.goods || [])]
  Results['goods'] = [...updatableGoods, ...goods.slice(0, splitCount)]
  const updateGoods = goods.slice(splitCount)
  const isDone = updateGoods.length === 0
  return [isDone, updateGoods]
}

let timer = null

/**
 * 渲染商品
 * @param {Array} goods - 商品列表
 * @param {Array} Results - 页面显示商品列表数据
 * @param {Function} render - 渲染函数
 **/
async function renderAndBathchItems(goods, Results, firstColumnRenderDone, render) {
  clearTimeout(timer)
  const clearnupFunctions = []

  const done = (...arg) => {
    render(...arg)
    // 删除填充的元素，防止页面插坑商品后有差异展示。
    timer = setTimeout(() => clearnupFunctions.forEach(fn => fn()), 500)
  }

  const [isDone, updateFirstGoods] = appendItemGoods(goods, Results, CHUNK_SIZE, true)
  
  yieldStrategy().then(() => {
    firstColumnRenderDone() // 第一列两个商品渲染完成
  })

  if (isDone) {
    done(updateFirstGoods)
    return 
  }
  await nextTick()
  firstColumnRenderDone() // 第一列两个商品渲染完成
  const waterfallEl = document.querySelector('.product-list-v2__list .waterfall')
  const itemImgEl = waterfallEl?.querySelector('.waterfall-column .product-card__main-img')
  const waterfallColumns = waterfallEl?.querySelectorAll('.waterfall-column') || []
  const imgWidth = itemImgEl?.offsetWidth || 0

  batchRender(updateFirstGoods).catch(e => {
    console.error(e.message)
    warn('批量渲染失败，使用普通渲染')
    done(goods)
  })

  async function batchRender(updatableGoods) {
    if (updatableGoods.length < CHUNK_SIZE || Results.goods?.length >= MAX_LIMIT_SIZE) {
      done(updatableGoods)
      _log(`end batch render, go on normal render. Results.goods.length: ${Results.goods?.length}`)
      return
    }
    await nextTick()
    const itemImgs = updatableGoods.slice(0, CHUNK_SIZE).map(item => item._imgThumb && item).filter(Boolean)
    const els = [...waterfallColumns].map(columnEl => columnEl.lastElementChild).filter(Boolean)
    if (!itemImgEl || els.length < CHUNK_SIZE || itemImgs.length < CHUNK_SIZE) {
      warn('不满足批量渲染条件')
      return done(updatableGoods)
    }
    _log('start batch render')
    els.forEach((columnEl, index) => {
      const item = itemImgs[index]
      const src = item._imgThumb
      item._imgNotFsp = true // 标记为当前商品不需要加图片的fsp
      const isSquareImg = src.includes('_square_')
      const containerEl = document.createElement('div')
      containerEl.style.cssText = 'position:relative;'
      containerEl.innerHTML = `
        <div style="position: absolute; top: 0; left: 0; width: 100%;">
          <img src="${src}" style="width: ${imgWidth}px; ${isSquareImg ? `height: ${imgWidth}px;object-fit: cover;` : 'object-fit: contain;'}" />
        </div>
      `
      columnEl.appendChild(containerEl)
      clearnupFunctions.push(() => containerEl?.remove())
    })
    await yieldStrategy()
    const [isDone, updateGoods] = appendItemGoods(updatableGoods, Results, CHUNK_SIZE, false)
    goods = updateGoods
    if (isDone) {
      done(goods)
      return
    }
    await yieldStrategy()
    batchRender(goods)
  }
}

export { isBatchOperation, renderAndBathchItems }
