function rafPromiseManager() {
  const cleanupSet = new Set()

  const rafPromise = (fn) => {
    let promise = !fn && new Promise(resolve => fn = resolve)
    const id = requestAnimationFrame(() => {
      fn() 
      cleanup.delete()
    })
    const cleanup = () => {
      cancelAnimationFrame(id)
      cleanupSet.delete(cleanup)
    }
    cleanup.delete = () => cleanupSet.delete(cleanup)
    cleanupSet.add(cleanup)
    return promise
  }

  const cancelRafPromises = () => {
    cleanupSet.forEach(cleanup => cleanup())
    cleanupSet.clear()
  }

  return {
    rafPromise,
    cancelRafPromises
  }
}

export { rafPromiseManager }
