/**
 * @file 列表插坑相关的action
 */

import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import {
  rankListComponentApiCache,
  surveyComponentApiCache,
  discountChannelCompApiCache,
  cccCommonComponentApiCache,
  relatedSearchApiCache,
} from 'public/src/pre_requests/modules/productList/api/bffApi.js'
import { goodsTransformation } from 'public/src/services/goodsItemInfo/goodsTransformation/index.js'
import { getSearchDiff, isShowSearchFeed } from './utils.js'

const getListPageParams = ({ catInfo, searchKeywords }) => {
  const params = {}
  if (catInfo.type === 'search') {
    Object.assign(params, {
      scene: 'search',
      word: searchKeywords?.is_suggest_res ? searchKeywords?.suggest_words : searchKeywords?.origin_words,
    })
  } else if (catInfo.type === 'entity') {
    Object.assign(params, {
      scene: 'real',
      cat_id: catInfo.entity_id,
    })
  } else if (catInfo.type === 'selection') {
    Object.assign(params, {
      scene: 'select',
      select_id: catInfo.select_id,
    })
  }
  return params
}

const getRankListComponentParams = ({ catInfo, cccConfig, goods, searchKeywords }) => {
  const end = (catInfo.page || 1) * (catInfo.limit || 10)
  const start = end - (catInfo.limit || 10)

  // 有rankListModuleSetting是已融合
  const { rankListModuleSetting } = cccConfig || {}
  let deliveryPlaceArr = []

  if (rankListModuleSetting) {
    // 已融合
    const deliverPlace = rankListModuleSetting?.deliverPlace || ''
    deliveryPlaceArr = deliverPlace ? deliverPlace.split(',').map(v =>{
      return v > 0 ? v - 1 : v
    }).filter(index => {
      return index >= start && index <= end
    }) : []

  } else {
    // 未融合
    const deliverItemLimitObject = cccConfig?.flowRankingList?.deliverItemLimitObject || {}
    deliveryPlaceArr = Object.keys(deliverItemLimitObject).filter(num => num >= start && num <= end)
  }

  const delivery_place = deliveryPlaceArr?.length ? Number(deliveryPlaceArr[0]) + 1 : undefined
  const expose_goods_id = catInfo.page <= 12 ? goods.slice(0, 240).map(item => item.goods_id).join(',') : ''
  const params = {
    sort: catInfo.sort || 0,
    delivery_place,
    expose_goods_id,
    ...getListPageParams({ catInfo, searchKeywords }),
  }
  return params
}

const adaptRankListComponent = (rankListComponentResult, { language, sheinClubInfo, catInfo }) => {
  let _rankListComponentResult = rankListComponentResult?.info
  if (_rankListComponentResult && _rankListComponentResult.title && _rankListComponentResult.products?.length) {
    const suggestedSaleType = catInfo.suggestedSaleTypeBff || ''
    const goods = _rankListComponentResult.products.map(productItem => {
      return goodsTransformation(productItem, {
        suggestedSaleType: suggestedSaleType,
        language: language || {},
        lang: gbCommonInfo.lang,
        isPaid: !!sheinClubInfo?.isPaid
      })
    })

    _rankListComponentResult = Object.assign({}, _rankListComponentResult, {
      version: 'v2',
      carrierData: {
        carrierSubType: Number(_rankListComponentResult.carrierSubType),
        cateIds: _rankListComponentResult.cate_id || '-',
      },
      suggestedSalePriceType: suggestedSaleType,
      goods,
    })
    delete _rankListComponentResult.products
  } else {
    _rankListComponentResult = null
  }
  return _rankListComponentResult
}

const processRankListComponent = ({ sum, cccConfig, catInfo, ListComponent, rankListComponentResult }) => {

  // 不展示榜单组件
  const EmptyFlowRankingList = { FlowRankingList: { Data: null, Abt: null } }
  const ListComponentAbt = (catInfo.type === 'store' || ListComponent?.p?.ListComponent) ? 'FlowRankingListC' : null

  if (!ListComponentAbt || !rankListComponentResult) {
    return EmptyFlowRankingList
  }

  const { delivery_place } = rankListComponentResult

  // 未融合用
  let flowRankCcc = cccConfig.flowRankingList

  // 融合用
  const rankListModuleSetting = cccConfig.rankListModuleSetting
  if (!delivery_place || (!flowRankCcc && !rankListModuleSetting?.deliverPlace)) {
    return EmptyFlowRankingList
  }

  // 融合后bff已经判断，这里不需要再判断
  if (flowRankCcc) {
    const minimumProductImpressions = flowRankCcc.deliverItemLimitObject?.minimumProductImpressions
    if (minimumProductImpressions && sum < minimumProductImpressions) {
      return EmptyFlowRankingList
    }
  } else if (rankListModuleSetting) {
    // 融合后需要伪装flowRankCcc
    flowRankCcc = {
      deliverPlaceItemsObject: {}
    }
  }

  flowRankCcc.deliverPlaceItemsObject[delivery_place] = rankListComponentResult

  return {
    Data: getSearchDiff(catInfo)?.hideFlowRankingList ? {} : {
      flowRankingList: flowRankCcc,
    },
    Abt: {
      ListComponentAbt,
    }
  }
}

// 榜单组件插坑
export async function getRankListComponentBff ({ state, getters }) {
  const { sum, language, sheinClubInfo, catInfo, cccConfig, goods, searchKeywords, listAbtResult } = getters
  const { ListComponent } = listAbtResult

  const params = getRankListComponentParams({ catInfo, cccConfig, goods, searchKeywords })
  if (!params.delivery_place || !params.scene) {
    return
  }
  const result = await rankListComponentApiCache.request(params)
  const rankListComponentResult = adaptRankListComponent(result, { language, sheinClubInfo, catInfo })
  // eslint-disable-next-line require-atomic-updates
  state.ComponentState.ProductList.flowDataV2.flowRank = processRankListComponent({ sum, cccConfig, catInfo, ListComponent, rankListComponentResult })
}

// 问卷组件插坑
export async function getSurveyComponentBff ({ state }) {
  if (!isSwitchBffApiVersion(['surveyQuestion_v1'])?.surveyQuestion_v1) {
    return
  }
  const catInfo = state.Results.cat_info || {}
  const { type, select_id, entity_id, entranceType, fromPageType  } = catInfo
  if (!['entity', 'selection', 'search', 'srctype', 'pick', 'store'].includes(type)) {
    state.ComponentState.ProductList.flowDataV2.flowRank = {} 
    return
  }
  const paramsMap = {
    entity: {
      entrance_type: entranceType || fromPageType || '',
      page_type: 'realListPage',
      cate_id: entity_id,
      cate_type: '4',
    },
    selection: {
      entrance_type: entranceType || fromPageType || '',
      page_type: 'selectListPage',
      cate_id: select_id,
      cate_type: '5',
    },
    search: {
      entrance_type: 'SEARCH',
      page_type: 'searchListPage',
      cate_type: '0',
    },
    pick: {
      entrance_type: 'INFO_FLOW',
      page_type: 'infoFlowListPage',
      cate_type: '0',
    },
    store: {
      entrance_type: 'STORE_LIST',
      page_type: 'shopListPage',
      cate_type: '0',
    },
  }
  const flowSurveyInfo = await surveyComponentApiCache.request(paramsMap[type])
  // eslint-disable-next-line require-atomic-updates
  state.ComponentState.ProductList.flowDataV2.flowSurveyInfo = flowSurveyInfo
}

const getPositionData = (positionArr, products) => {
  const positionData = {}
  let current = 0
  for (let i = 0; i < positionArr.length; i++) {
    const index = positionArr[i]
    positionData[index - 1] = products.slice(current, current + 8)
    current = current + 8
  }
  return positionData
}

const adaptDiscountChannelComp = (result, { language, sheinClubInfo, catInfo, listAbtResult, searchKeywords }) => {
  let _result = result?.info
  if (_result && _result.products?.length) {
    const ShowListDeals = listAbtResult.ShowListDeals || {}
    const positionArr = ShowListDeals.p?.DealsPosition?.split(',') || [] // 坑位

    const goods = _result.products.map(productItem => {
      return goodsTransformation(productItem, {
        suggestedSaleType: catInfo.suggestedSaleTypeBff || '',
        language: language || {},
        lang: gbCommonInfo.lang,
        isPaid: !!sheinClubInfo?.isPaid
      })
    })
    return {
      positionData: getPositionData(positionArr, goods),
      discountParams: {
        ...getListPageParams({ catInfo, searchKeywords }),
        title: _result.title,
        subTitle: _result.subTitle,
        suggestedSalePriceType: catInfo.suggestedSaleTypeBff || '',
        DealsEnchance: ShowListDeals?.p?.DealsEnchance || 'A',
      },
    }
  }
  return null
}

// 折扣频道插坑
export async function getDiscountChannelCompBff ({ state, getters }) {
  const { catInfo, searchKeywords, language, sheinClubInfo, listAbtResult } = getters
  if (!(catInfo.isRealPage || catInfo.isSelectPage || catInfo.isSearchPage)) {
    return
  }
  const params = getListPageParams({ catInfo, searchKeywords })
  const result = await discountChannelCompApiCache.request(params)
  const flowDiscountChannel = adaptDiscountChannelComp(result, { catInfo, language, sheinClubInfo, listAbtResult, searchKeywords })
  state.ComponentState.ProductList.flowDataV2.flowDiscountChannel = flowDiscountChannel
}

const adaptFlowListBanner = (cccCommonComponentResult) => {
  const _cccCommonComponentResult = cccCommonComponentResult?.info
  if (!_cccCommonComponentResult) {
    return
  }
  const cateLinks = {}
  const banners = (_cccCommonComponentResult.content || []).filter(contentItem => {
    return contentItem.blockKey === 'flowList_Banner'
  }).map(bannerItem => {

    (bannerItem.props?.items || []).forEach(item => {
      cateLinks[`${item.hrefType}_${item.hrefTarget}`] = item.webClickUrl
    })

    return {
      ...bannerItem,
      __comp_name: 'StaticImageCardController',
    }
  })

  if (banners.length === 0) {
    return
  }
  const adRecord = {
    cateLinks,
    abtBranch: _cccCommonComponentResult.abtBranch,
    channelId: _cccCommonComponentResult.channelId,
    id: _cccCommonComponentResult.id,
    pageType: _cccCommonComponentResult.pageType,
    templateId: _cccCommonComponentResult.templateId,
    templateKey: _cccCommonComponentResult.templateKey,
    content: banners,
  }
  return {
    context: adRecord,
    data: adRecord.content.reduce((total, curr) => {
      total[curr.placeHolderPosition - 1] = curr
      return total
    }, {}),
  }
}

// 包含广告流插坑
export async function getCccCommonComponentBff ({ state, getters }) {
  const { catInfo, searchKeywords } = getters
  const listPageParams = getListPageParams({ catInfo, searchKeywords })
  if (!['real', 'select'].includes(listPageParams.scene)) {
    return
  }
  const params = {
    cccPageType: 'realSelectListPage',
    ...(listPageParams.scene === 'real' ?
      { cateId: listPageParams.cat_id, cateType: 4 } :
      { cateId: listPageParams.select_id, cateType: 5 }),
  }
  const result = await cccCommonComponentApiCache.request(params)
  state.ComponentState.ProductList.flowDataV2.flowListBanner = adaptFlowListBanner(result)
}

// 搜了还搜
export async function getRelatedSearchBff ({ getters: { language, cccOperation: { mobile_guided_search }, listAbtResult: { SearchFeedHotword, RelatedSearchNew }, catInfo, searchKeywords, isTopTrendResultPage, sum } }) {
  // v.趋势搜索结果页屏蔽该功能
  if (isTopTrendResultPage || !sum) return
  let mobile_guided_search_data = mobile_guided_search?.SearchFeedHotword
  // abt控制搜了还搜位置 优先级 > CCC
  if (RelatedSearchNew?.p?.RelatedSearchLoc) {
    const { RelatedSearchLoc } = RelatedSearchNew.p
    mobile_guided_search_data = RelatedSearchLoc.split('&')
  }
  if (!isShowSearchFeed({
    RelatedSearchNewParams: RelatedSearchNew?.p,
    SearchFeedHotwordParams: SearchFeedHotword?.p,
    mobileGuidedSearch: mobile_guided_search_data,
    requestType: catInfo.requestType
  })) return
  const params = {
    keyword: searchKeywords.keywords,
    scene: '1', // 1：有结果搜了还搜请求；2: 无结果推荐词请求；3：商品点击后推荐搜索词
    word_type: '1' // 1为默认词, 2为热搜词
  }
  const result = await relatedSearchApiCache.request({ params })
  const keywords = result?.info?.keywords ?? []
  if (!keywords?.length || keywords.length < 2) return
  const isWaterfall = true
  // 非瀑布流 少于4个不展示；瀑布流 少于2个不展示
  const sliceNum = isWaterfall ? Math.ceil(keywords.length / 4) : Math.floor(keywords.length / 4)
  return {
    FlowSearch: {
      Data: getSearchDiff(catInfo)?.hideRelatedSearch ? {} : mobile_guided_search_data
        .slice(0, sliceNum) // [搜了还搜]少于 4 个不展示; 单列; 不区分是否瀑布流
        .reduce((curr, next) => {
          const currData = keywords.splice(0, 4)
          if (currData.length >= 2) curr[next - 1] = currData
          return curr
        }, {}),
      CommonData: {
        searchLang: language.SHEIN_KEY_PWA_16836,
      },
      Abt: {
        SearchFeedHotword: RelatedSearchNew,
      },
    }
  }
}
